import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { resetPrompts } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { openURL } from '../../utils/openURL';
import { Dialog } from '../Dialog';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './GhostreaderPromptsOptionsDropdown.module.css';

type Props = {
  onResetAllPrompts: () => void;
};

export default function GhostreaderPromptsOptionsDropdown({ onResetAllPrompts }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [showResetPromptsDialog, setShowResetPromptsDialog] = useState(false);
  const history = useHistory();

  const options = useMemo(() => {
    return [
      {
        type: DropdownOptionType.Item,
        name: 'View Ghostreader help',
        onSelect: () => {
          openURL('https://docs.readwise.io/reader/guides/ghostreader/overview', '_blank');
        },
      },
      {
        type: DropdownOptionType.Item,
        name: 'Add OpenAI API Key',
        onSelect: () => history.push('/integrations'),
      },
      {
        type: DropdownOptionType.Separator,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Reset built-in prompts',
        isDanger: true,
        onSelect: () => setShowResetPromptsDialog(true),
      },
    ];
  }, [history]);

  return (
    <>
      <Dropdown
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        appendToDocumentBody
        contentClassName={styles.dropdownContent}
        triggerClassName={styles.triggerElement}
      />
      {showResetPromptsDialog && (
        <Dialog
          id="reset-built-in-prompts"
          title="Reset built-in prompts?"
          subtitle="This will reset all built-in prompts to their default values. Any custom prompts you’ve created will not be modified."
          actionTitle="Reset built-in prompts"
          cancelTitle="Cancel"
          redActionButton
          action={async () => {
            setShowResetPromptsDialog(false);
            await resetPrompts();
            createToast({
              content: 'Built-in prompts have been reset',
              category: 'success',
            });
            onResetAllPrompts();
          }}
          cancelAction={() => setShowResetPromptsDialog(false)}
        />
      )}
    </>
  );
}
