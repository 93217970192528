import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  type Prompt,
  PromptScopeType,
  PromptType,
  transformPrompts,
  useDefaultPrompts,
} from '../../../../../shared/foreground/ghostreader';
import { globalState } from '../../../../../shared/foreground/models';
import {
  createPrompt,
  deletePrompt,
  migratePromptsFormatV1,
  resetPrompt,
  toggleIsCreatedPromptEnabled,
  toggleIsPromptEnabled,
  updateCreatedPrompt,
  updatePrompt,
} from '../../../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import GhostreaderPromptsOptionsDropdown from '../../Dropdown/GhostreaderPromptsOptionsDropdown';
import SearchIcon from '../../icons/20StrokeSearch';
import { AccountSettingsPage } from '../AccountSettingsPage';
import { NewPrompt } from './components/NewPrompt';
import { PromptDetails } from './components/PromptDetails';
import styles from './GhostreaderPromptsPage.module.css';
import { useFilteredPrompts } from './hooks';
import type { PromptDetailsHandle } from './types';
import { promptId } from './utils';

export const GhostreaderPromptsPage = () => {
  const [currentEditablePromptId, setCurrentEditablePromptId] = useState<string>();

  const [overriddenPrompts, createdPrompts] = globalState((state) => [
    state.persistent.settings.overriddenPrompts2 ?? state.persistent.settings.overriddenPrompts,
    state.persistent.settings.createdPrompts,
  ]);

  const defaultPrompts = useDefaultPrompts();
  const prompts = transformPrompts(defaultPrompts, overriddenPrompts, createdPrompts);

  const onRemove = async (promptScopeType: PromptScopeType, prompt: Prompt) => {
    await deletePrompt(promptScopeType, prompt);
  };

  const onReset = async (promptScopeType: PromptScopeType, prompt: Prompt) => {
    await resetPrompt(promptScopeType, prompt.type);
    const promptDetails = promptRefs.current.get(promptId(promptScopeType, prompt));
    if (promptDetails) {
      promptDetails.reset();
    }
  };
  const onUpdate = async (scope: PromptScopeType, prompt: Prompt) => {
    setCurrentEditablePromptId(undefined);

    if (prompt.type === PromptType.Generic) {
      await updateCreatedPrompt(scope, prompt);
    } else {
      // migrate existing prompts (once) if necessary
      await migratePromptsFormatV1(defaultPrompts);
      await updatePrompt(scope, prompt);
    }
  };
  const promptRefs = useRef<Map<string, PromptDetailsHandle | null>>(new Map());

  const onResetAllPrompts = () => {
    for (const [, promptRef] of promptRefs.current) {
      promptRef?.reset();
    }
  };

  const onCancelPromptEditing = useCallback(() => {
    setCurrentEditablePromptId(undefined);
  }, []);

  const onAddPrompt = async (scope: PromptScopeType) => {
    const prompt = await createPrompt(scope);
    setCurrentEditablePromptId(promptId(scope, prompt));
  };

  const onToggleIsEnabled = async (scope: PromptScopeType, prompt: Prompt) => {
    if (prompt.type === PromptType.Generic) {
      await toggleIsCreatedPromptEnabled(scope, prompt);
    } else {
      await toggleIsPromptEnabled(scope, prompt);
    }
  };

  // filter prompts when searching
  const { filteredPrompts, search } = useFilteredPrompts(prompts);

  return (
    <AccountSettingsPage
      title="Ghostreader prompts"
      additionalHeaderContent={
        <div className={styles.searchWrapper}>
          <SearchIcon className={styles.searchIcon} />
          <input
            aria-labelledby="search-label"
            type="search"
            autoComplete="off"
            placeholder="Find prompts..."
            onChange={(e) => search(e.target.value)}
          />
        </div>
      }
    >
      <div className={styles.headerWrapper}>
        <h1 className={styles.mainHeader}>
          <Link to="/preferences">Preferences /</Link> Ghostreader prompts
        </h1>
        <div className={styles.layoutDropdownWrapper}>
          <GhostreaderPromptsOptionsDropdown onResetAllPrompts={onResetAllPrompts} />
        </div>
      </div>

      <ul className={styles.ghostreaderPromptsList}>
        {Array.from(filteredPrompts.values(), (scope) => {
          if (scope.isCustomizable !== undefined && !scope.isCustomizable) {
            return;
          }

          let currentScope: typeof scope;
          const prompts = Array.from(scope.prompts.values(), (prompt) => {
            const result = [];
            if (currentScope !== scope) {
              currentScope = scope;
              result.push(
                <li className={styles.categoryHeader} key={`scope-${scope.type}`}>
                  <h2 className={styles.scopeTitle}>{scope.title}</h2>
                  {scope.description && <h3 className={styles.scopeDescription}>{scope.description}</h3>}
                </li>,
              );
            }

            result.push(
              <PromptDetails
                ref={(el) => {
                  promptRefs.current.set(promptId(scope.type, prompt), el);
                }}
                key={promptId(scope.type, prompt)}
                prompt={prompt}
                scope={scope}
                isEditable={promptId(scope.type, prompt) === currentEditablePromptId}
                onCancel={onCancelPromptEditing}
                onEdit={setCurrentEditablePromptId}
                onRemove={onRemove}
                onReset={onReset}
                onToggleIsEnabled={onToggleIsEnabled}
                onUpdate={onUpdate}
              />,
            );

            return result;
          });

          if (scope.type !== PromptScopeType.Automatic) {
            prompts.push([
              <NewPrompt promptScopeType={scope.type} onClick={onAddPrompt} key="new-prompt" />,
            ]);
          }

          return prompts;
        })}
      </ul>
    </AccountSettingsPage>
  );
};
